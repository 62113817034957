.article section ul,
.article section ol {
    padding-left: 32px;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
}

.article section p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.article section *:not(#table *) {
    word-break: break-word;
}

.article section {
    margin-bottom: 2rem;
}

.article section a {
    text-decoration: underline;
    text-underline-offset: 0.1em;
    &:hover {
        text-decoration: none;
    }
}

.article .content-button {
    text-decoration: none;
}

.article section h3,
.article section h4 {
    margin-bottom: 0;
    margin-top: 0.5rem;
    clear: both;
}

.article section h2 {
    margin-bottom: 0.5rem;
    clear: both;
}

.article table {
    overflow: initial !important;
}

/* Note serializer style */

.MuiAccordionSummary-content {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.MuiAccordionSummary-content span p {
    margin: 0;
}

.MuiAccordionDetails-root {
    margin-top: 1rem;
}

/* "NHI Standard Tables", table styling from CMS */

.article table.nhi-std-title tr:first-child {
    background-color: #f5f5f5;
}

.article table.nhi-std-title tr:first-child td {
    font-weight: bold;
}

.article table.nhi-std-row-headers tr:nth-child(2) {
    background-color: #f5f5f5;
}

.article table.nhi-std-row-headers tr:nth-child(2) td {
    font-weight: bold;
}

.article table.nhi-std-column-headers tr td:first-child {
    background-color: #fafafa;
    font-weight: bold;
}

.article table.nhi-std-striped tr:nth-child(2n + 4) {
    background-color: #fafafa;
}

.article table.smallfont td {
    font-size: 0.75rem;
}

.article table td p {
    margin: 0;
}

.article table td ul {
    margin: 0;
    padding-left: 1rem;
}
