@import "./article.css";

/**
 * IMBPlexSans -used in overline
 */

@font-face {
    font-family: "IBMPlexSans";
    src: url("/fonts/IBMPlexSans-Regular.woff2");
    format: ("woff2");
    font-display: swap;
    font-weight: 400;
}
/**
 * IMBPlexSerifSemibold -used in h1,h2,h3
 */

@font-face {
    font-family: "IBMPlexSerifSemibold";
    src: url("/fonts/IBMPlexSerif-SemiBold.woff2");
    format: ("woff2");
    font-display: swap;
    font-weight: 600;
}

/**
 * IBMPlexSerifExtraLightItalic -used in pregnancyoracle 
 */

@font-face {
    font-family: "IBMPlexSerifExtraLightItalic";
    src: url("/fonts/IBMPlexSerif-ExtraLightItalic.woff2");
    format: ("woff2");
    font-display: swap;
}

/**
 * Figtree -used in body, h4, h5,h6, subtitle1, subtitle1, caption
 */

@font-face {
    font-family: "Figtree";
    src: url("/fonts/Figtree-Regular.woff2");
    format: ("woff2");
    font-display: swap;
    font-weight: 400;
}

@font-face {
    font-family: "FigtreeLight";
    src: url("/fonts/Figtree-Light.woff2");
    format: ("woff2");
    font-style: light;
    font-display: swap;
    font-weight: 300;
}

@font-face {
    font-family: "FigtreeItalic";
    src: url("/fonts/Figtree-Italic.woff2");
    format: ("woff2");
    font-style: italic;
    font-display: swap;
    font-weight: 400;
}

@font-face {
    font-family: "FigtreeSemibold";
    src: url("/fonts/Figtree-SemiBold.woff2");
    format: ("woff2");
    font-display: swap;
    font-weight: 600;
}

/* Swiper custom styles used in CarouselContainer component */
.swiper-pagination-bullet {
    outline: 1px solid #1f394c !important;
    opacity: 1 !important;
    background: transparent !important;
}
.swiper-pagination-bullet-active {
    background: #1f394c !important;
}
